.teamEm {
  display: flex;
  background: #fff;
  padding: 2vw;
  padding-right: 4vw;
  width: 94%;
  margin: 0 auto;
  border-radius: 0 8vw 8vw 8vw;
  filter: drop-shadow(0px 6px 25px rgba(56, 151, 239, 0.3));
  margin-top: 5vw;
}
.Team {
  background: #eef1f8;
  padding-bottom: 5vw;
}
.Team .znaks {
  position: static;
  margin: 0 auto;
  margin-top: -3vw;
  margin-bottom: 9vw;
}
.tovarT {
  padding: 6vw 3vw;
  background: #fff;
  border-radius: 6vw;
  display: flex;
  align-items: center;
  position: relative;
  width: 90vw;
  margin: 0 auto;
  margin-bottom: 5vw;
  box-shadow: 0px 20px 40px 0px rgba(4, 78, 221, 0.3);
}
.Team .sendForm {
  padding: 4vw 12vw;
  border-radius: 15vw;
  filter: drop-shadow(0px 20px 40px rgba(15, 82, 255, 0.3));
  width: fit-content;
  margin: 0 auto;
  margin-top: 10vw;
}
.tovarT div {
  position: absolute;
  top: 2vw;
  right: 2vw;
  border-radius: 50%;
  font-family: "Coolvetica Rg";
  background: #dfe8ff;
  color: #a7a5f4;
  width: 6vw;
  height: 6vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 5vw;
  font-weight: 400;
}
.besp {
  margin-top: 3vw;
  text-align: center;
  font-size: 5vw;
  font-family: "Coolvetica Rg";
  margin-bottom: 10vw;
}
.tovarT p {
  font-family: "Coolvetica Rg";
  width: 70vw;
  font-size: 4.3vw;
}
.tovarT img {
  margin-right: 4vw;
  width: 15vw;
  filter: drop-shadow(0px 4px 10px rgba(36, 0, 64, 0.28));
}
.teamEm img {
  width: 35vw;
  margin-right: 4vw;
}
.teamEm h4 {
  margin: 4vw 0;
  font-size: 4.5vw;
  margin-top: 6vw;
}
.line {
  width: 100%;
  height: 1px;
  background: rgba(0, 104, 255, 0.28);
}
.teamEm p {
  color: rgba(67, 104, 157, 1);
  margin: 4vw 0;
  font-size: 3.5vw;
}
.call {
  background: #7ec800;
  color: #fff;
  padding: 2.5vw 4vw;
  width: fit-content;
  border-radius: 10vw 0 10vw 6vw;
  filter: drop-shadow(0px 2px 11px rgba(101, 197, 67, 0.72));
  margin-top: 6vw;
}

@keyframes movingFlare {
  0% {
    left: -20vw;
    margin-left: 0px;
  }
  30% {
    left: 110%;
    margin-left: 80px;
  }
  100% {
    left: 110%;
    margin-left: 80px;
  }
}
