.formHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.loader {
  width: 8.5vw;
  height: 8.5vw;
  border: 1.3vw solid #bcbcbc;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  position: absolute;
  left: 45.5%;
  top: 62vw;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.formHeader h3 {
  color: #0068ff;
  font-size: 8vw;
  text-align: center;
  line-height: 8vw;
  font-family: "Coolvetica Rg";
  margin-top: 6vw;
  text-transform: uppercase;
  background: linear-gradient(
    180deg,
    #0068ff 32.29%,
    rgba(0, 104, 255, 0.69) 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.Form {
  background-color: #eef1f8;
  padding-bottom: 12vw;
}
.formHeader h4 {
  text-align: center;
  font-size: 4.5vw;
  width: auto;
  margin-top: 2vw;
  font-family: "Coolvetica Rg";
  padding-bottom: 8vw;
}
.form {
  filter: drop-shadow(0px 6px 25px rgba(143, 201, 255, 0.51));
  background: #fff;
  padding: 0vw;
  border-radius: 8vw;
  width: 92%;
  margin: 0 auto;
  margin-top: 8vw;
}
.formBody {
  border-radius: 5vw;
  background: #fff;
  padding: 5vw;
  display: flex;
  flex-direction: column;
  padding-top: 6vw;
}
.inputsForm input {
  padding: 4vw 4vw;
  font-size: 5vw;
  background: #d1d9eb;
  font-weight: bold;
  width: 100%;
  background: #daeafe;
  border-radius: 8vw;
  outline: none;
  border: none;
}
.inputsForm p {
  font-size: 3.5vw;
  margin-left: 0vw;
  margin-bottom: 2vw;

  font-family: "Coolvetica Rg";
}
.inputsForm {
  margin-bottom: 6vw;
}
.sendForm {
  background: #0068ff;

  border-radius: 8vw;
  padding: 5vw 4vw;
  width: 100%;
  font-family: "Coolvetica Rg";
  color: #fff;
  font-size: 5vw;
  text-align: center;
  font-weight: 6500;
  margin-bottom: 1vw;
}
.vibor label {
  margin-right: 4vw;
}
.vibor {
  display: flex;
  align-items: center;
  margin-bottom: 6vw;
}
.vibor input {
  width: 5vw;
  margin-right: 2vw;
  height: 5vw;
}
