.serviceItem {
  background: #fff !important;
  box-shadow: 0px 10px 50px 0px rgba(15, 82, 255, 0.25);
  padding: 1.5vw;
  padding-top: 0;
  border-radius: 6vw;
  width: 94%;
  margin: 0 auto;
  margin-top: 5vw;
}
.services {
  background: #eef1f8;
  padding-bottom: 8vw;
}
.etpas {
  font-family: "Coolvetica Rg";
  font-size: 6vw;
  padding: 2.5vw 4vw;
  padding-bottom: 3vw;

  color: #fff;
  margin: 0 auto;
  margin-top: -2vw;
  width: fit-content;
  border-radius: 100px;
  background: linear-gradient(90deg, #56c2ff 0.38%, #5956ff 99.84%);
  box-shadow: 0px 11px 27px 0px rgba(60, 129, 233, 0.54);
}
.serviceItemHeader {
  width: 90%;
  margin: 0 auto;
  border-radius: 0px 0px 20px 20px;
  background: linear-gradient(224deg, #55e411 9.67%, #00b268 89.66%);
  box-shadow: 0px 10px 30px 0px rgba(0, 231, 92, 0.3);
  padding: 3vw 1.5vw;
  margin-bottom: 3vw;
  display: flex;
  align-items: center;
}
.price {
  text-align: center;
  font-family: "Coolvetica Rg";
  text-transform: uppercase;
  font-size: 8vw;
  margin-right: 9vw;
  background: linear-gradient(
    180deg,
    #0068ff 32.29%,
    rgba(0, 104, 255, 0.69) 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding-top: 6vw;
  border-top: 2px solid rgba(197, 212, 234, 1);
}
.dokup {
  font-family: "Coolvetica Lt";
  text-align: center;
  margin-left: 3vw;
  font-size: 4vw;
  font-weight: 400;
}
.sendButton {
  font-family: "Coolvetica Rg";
  font-size: 4.5vw;
  width: fit-content;
  padding: 4vw 10vw;
  border-radius: 10vw;
  background: #0068ff;
  color: #fff;
  margin-bottom: 3vw;
  margin-left: 16.5vw;
  margin-top: 5vw;
}
.serviceItemHeader p div {
  font-family: "Coolvetica Rg";
  text-transform: uppercase;
  color: #fff;
  font-weight: 400;
  font-size: 5.5vw;
  text-align: center;
}
.serviceS .serviceItemHeader {
  width: 60%;
  background: linear-gradient(224deg, #12a5f9 9.67%, #0068ff 89.66%);
  box-shadow: 0px 10px 30px 0px rgba(0, 189, 231, 0.3);
}
.serviceS2 .serviceItemHeader {
  background: linear-gradient(224deg, #252efe 9.67%, #a346ff 89.24%);

  box-shadow: 0px 10px 30px 0px rgba(60, 0, 231, 0.3);
}
.serviceS2 li::marker {
  color: #a346ff !important;
}
.serviceS2 .price {
  background: linear-gradient(224deg, #252efe 9.67%, #a346ff 89.24%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.serviceS3 .price {
  background: linear-gradient(224deg, #e00000 9.67%, #ff7a00 89.24%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.serviceS3 li::marker {
  color: #e00000 !important;
}
.serviceS3 .serviceItemHeader {
  background: linear-gradient(224deg, #e00000 9.67%, #ff7a00 89.24%);
  box-shadow: 0px 10px 30px 0px rgba(255, 63, 63, 0.3);
}
.serviceS .spisok {
  justify-content: center;
}
.serviceItemHeader p span {
  font-family: "Coolvetica Rg";
  font-weight: 400;
  color: #fff;
  font-size: 5vw;
  text-align: center;
}
.ask {
  filter: drop-shadow(0px 2px 11px rgba(101, 197, 67, 0.72));
  background: #7ec800;
  text-transform: uppercase;
  color: #fff;
  padding: 3vw 5vw;
  font-size: 3.2vw;
  text-align: center;
  width: fit-content;
  border-radius: 0 8vw 3vw 8vw;
  font-weight: 600;
  float: right;
}

.serviceS {
  margin-bottom: 8vw;
}
@keyframes movingFlare {
  0% {
    left: -20vw;
    margin-left: 0px;
  }
  30% {
    left: 110%;
    margin-left: 80px;
  }
  100% {
    left: 110%;
    margin-left: 80px;
  }
}
.serviceItemHeader p {
  font-size: 3.2vw;
  font-weight: 700;
  text-align: center;
  width: 100%;
}
.serviceItemHeader svg {
  width: 8vw;
  margin-left: 3vw;
  margin-right: 2vw;
}
.serviceUl {
  color: black;
  font-size: 4vw;
  padding-left: 8vw;
  padding-top: 4vw;

  padding-bottom: 3vw;
  transition: 1.5s;
}
.serviceUl li {
  margin-bottom: 5vw;
  padding-right: 4vw;
}
.serviceUl li::marker {
  color: rgba(86, 194, 255, 1);
}
.title {
  list-style-type: none;
  display: flex;
  align-items: center;
  margin-left: -4vw;
}
.title span {
  font-family: "Coolvetica Rg";
  margin-right: 2vw;
  font-size: 5vw;
}
.spisok {
  color: #0068ff;
  font-family: "Coolvetica Rg";
  display: flex;
  align-items: center;
  font-size: 3.5vw;
  padding: 2vw;
  margin-left: 1.5vw;
}
.spisok p {
  font-family: "Coolvetica Rg";
}
.spisok svg {
  margin-left: 2vw;
  margin-top: 0.4vw;
}
