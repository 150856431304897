.objEm {
  background: #fff;
  padding: 3vw;
  border-radius: 6vw 6vw 10vw 0;

  width: 94%;
  margin: 0 auto;
  margin-top: 8vw;
}
.objEm h4 {
  text-align: center;
  font-size: 7vw;
  margin-bottom: 3vw;
}
.twoSpecial {
  margin-top: 4vw;
  display: flex;
  justify-content: space-between;
}
.twoSpecial .special {
  width: 44vw;
}
.objects .sendForm {
  width: 60vw;
  margin: 0 auto;
}
.pis {
  margin: 6vw auto;
  font-size: 5.5vw;
  font-family: "Coolvetica Rg";
  text-align: center;
}
.pis span {
  color: #0068ff;
  font-family: "Coolvetica Rg";
}
.twoSpecial .special {
  background: linear-gradient(117deg, #82d665 2.61%, #009444 99.2%);
  box-shadow: 0px 8px 30px 0px rgba(15, 255, 39, 0.3);
}
.twoSpecial .special:last-child {
  background: linear-gradient(117deg, #a97af5 2.61%, #6139ff 99.2%);
  box-shadow: 0px 8px 30px 0px rgba(92, 15, 255, 0.3);
}
.twoSpecial .special .topSpecial {
  flex-direction: column;
}
.twoSpecial .special .topSpecial p {
  margin-left: 0;
  font-size: 5vw;
  margin-top: 3vw;
}
.twoSpecial .special .bottomSpecial p {
  font-size: 3.6vw;
  text-align: center;
}
.special {
  background: linear-gradient(117deg, #f57a7a 2.61%, #ff3945 99.2%);
  width: 90vw;
  border-radius: 7vw;
  color: #fff;
  padding: 5vw;
  margin: 0 auto;
  box-shadow: 0px 8px 30px 0px rgba(255, 15, 15, 0.3);
}
.zag {
  font-family: "Coolvetica Rg";
  text-align: center;
  font-size: 8vw;
  text-transform: uppercase;
  margin-top: 2vw;
  background: linear-gradient(
    180deg,
    #0068ff 32.29%,
    rgba(0, 104, 255, 0.69) 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.pred {
  font-family: "Coolvetica Rg";
  color: #313662;
  text-align: center;
  text-transform: uppercase;
  font-size: 8vw;
  margin-bottom: 6vw;
}
.topSpecial {
  display: flex;
  align-items: center;
  margin-bottom: 4vw;
  padding-bottom: 4vw;
  border-bottom: 2px solid rgba(255, 255, 255, 0.15);
}
.bottomSpecial p {
  font-family: "Coolvetica Rg";
  font-weight: 500;
  font-size: 5vw;
}
.topSpecial p {
  color: #fff;

  text-align: center;
  font-family: "Coolvetica Rg";
  font-size: 6.5vw;
  margin-left: 3vw;

  font-style: normal;
  font-weight: 400;
}
.objects {
  background: #eef1f8;
  padding-bottom: 12vw;
  min-height: 100vh;
}
.hots {
  position: absolute;
  z-index: 22;
  top: 17vw;
  left: 6vw;
  width: 85%;
}
.hotEm {
  display: inline-block;
  width: fit-content;
  padding: 1vw 2vw;
  margin-bottom: 2vw;
  border-radius: 5vw;
  margin-right: 2vw;
}
.getPr {
  background: #7ec800;
  filter: drop-shadow(
    0px 4.7484660148620605px 26.11656379699707px rgba(101, 197, 67, 0.72)
  );
  padding: 4vw 10vw;
  padding-bottom: 2vw;
  width: 100%;
  text-align: center;
  font-size: 4vw;
  color: #fff;
  border-radius: 6vw 6vw 10vw 0;
  margin-top: 5vw;
  text-transform: uppercase;
}

@keyframes movingFlare {
  0% {
    left: -20vw;
    margin-left: 0px;
  }
  30% {
    left: 110%;
    margin-left: 80px;
  }
  100% {
    left: 110%;
    margin-left: 80px;
  }
}
.rel {
  position: relative;
}
.rel img {
  width: 100%;
}
.priceObj {
  position: absolute;
  bottom: 4vw;
  font-size: 5vw;
  right: 3vw;
  color: #43689d;
  padding: 1vw 2vw;
  background: #fff;
  border-radius: 6vw;
}
