.thanks {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-size: contain;
  background-repeat: no-repeat;
  height: 96vh;
}
.thanksBody {
  padding: 5vw;
  border-radius: 10vw 0 0 0;
  background: #fff;
  display: flex;
  flex-direction: column;
  min-height: 100vw;
  align-items: center;
  width: 100%;
}
.thanksBody img {
  width: 30vw;
}
.thanksBody h4 {
  text-align: center;
  font-size: 6vw;
}
.thanksBody p {
  font-size: 5vw;
  margin: 3vw 0;
  color: #538b9d;
  text-align: center;
}
.mainTo {
  background: #7ec800;
  color: #fff;
  text-transform: uppercase;
  padding: 5vw 3vw;
  width: 100%;
  margin-top: 3vw;
  text-align: center;
  font-size: 4.5vw;
  filter: drop-shadow(0px 2px 11px rgba(101, 197, 67, 0.72));
  border-radius: 8vw 5vw 8vw 0;
}
