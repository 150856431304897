.back {
  display: flex;
  align-items: center;
  color: #2e86ff;
  font-size: 3.6vw;
  padding: 3.3vw 4vw;
  box-shadow: 0px 11px 27px 0px rgba(60, 129, 233, 0.54);
  border-radius: 10vw;
  font-weight: 600;
  width: fit-content;
  background: #fff;
}
.back img {
  margin-right: 1.5vw;
  width: 3vw;
}
.headerMain {
  display: flex;
  background: url(./fon.png);
  background-size: cover;
  translate: 0 1px;

  background-position: center 0px;
  align-items: center;
  width: 100%;
  padding: 3vw;
  padding-top: 5vw;
  padding-bottom: 11vw;
  justify-content: space-between;
}
.tel {
  display: flex;
  align-items: center;
  color: #fff;
  background: rgba(0, 104, 255, 0.79);
  font-weight: 600;
  border-radius: 10vw;
  padding-left: 5vw;
  justify-content: space-between;
  box-shadow: 0px 11px 27px 0px rgba(60, 129, 233, 0.54);
  width: fit-content;
}
.tel div {
  display: flex;
  align-items: center;
  border-radius: 10vw;
  padding: 3vw 3vw;
  background: #0068ff;
}
.copied_phone {
  transition: 0.5s;
  position: fixed;
  z-index: 10;
  top: -15vh;
  border-radius: 3vw;
  padding: 4vw 8vw;
  width: 88%;
  text-align: center;
  background: #ffffff;
  color: #000000;
  font-weight: bold;
  font-size: 5vw;
  box-shadow: 0px 0px 60px 0px rgba(0, 83, 139, 0.25);
  border: 0.4vw solid rgba(0, 194, 255, 1);
  left: 50%;
  transform: translateX(-50%);
}
.tel input {
  background: #0068ff;
  border: none;
  font-weight: 600;
  width: 30vw;
  color: #fff;
  text-decoration: none;
  font-size: 3.8vw;
  outline: none;
}
.tel img {
  width: 5vw;
  margin-right: 2vw;
}
.tel p {
  font-size: 3vw;
  margin-right: 2vw;
}
